// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "20.4.0",
  "buildNumber": "11749",
  "gitSha": "5553066d29d315951efa5ead0c087374df6338b9",
  "fullVersion": "20.4.0-11749",
  "formattedVersion": "20.4.0 (11749-5553066d29d315951efa5ead0c087374df6338b9)",
  "copyrightYear": "2020"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/20.4.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/20.4.0/${stripLeadingPrefix(suffix, '#')}`
}
    
